/* Add this CSS to your styles or inside a CSS module */

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
}

@keyframes popInOut {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }
}

.pulsing-trend-title-1 {
    animation: pulse 2s infinite;
    text-shadow: none; /* Ensure no text shadow */
    border: none; /* Remove any borders */
    outline: none; /* Remove any outlines */
    box-shadow: none; /* Remove box shadows */
    background: none; /* Ensure no background color */
    margin: 0; /* Reset margins to avoid visual artifacts */
    padding: 0; /* Reset padding to avoid visual artifacts */
}