/* Main container */
.settings-container {
    display: flex;
    align-items: flex-start;
    padding-left: 24px;
    padding-right: 24px;
}

/* Button group styling */
.button-group {
    display: flex;
    flex-direction: row;
}

/* Applied filters section styling */
.applied-filters {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.filter-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.filter-box {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 8px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .settings-container {
        flex-direction: column; /* Stack vertically on small screens */
    }

    .button-group {
        flex-direction: column;
        margin-bottom: 20px; /* Add space between buttons and filters */
    }

    .applied-filters {
        margin-left: 0; /* Remove left margin for mobile */
    }
}


/* Header.css */
.header {
    background-color: #001529;
    display: flex;
    align-items: left;
}

.header-title {
    color: #fff !important; /* Ensure text color is white */
    margin: 0;
    font-size: 10px;
    text-align: center;
}

/* Adjust font size for mobile screens */
@media (max-width: 768px) {
    .header-title {
        font-size: 16px;
    }
}

/* Add this CSS rule to your main CSS file */
@media (max-width: 768px) {
    .desktop-only {
        display: none;
    }
}

.aqi-chart-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.aqi-header {
    background-color: #001529;
    padding: 10px 20px;
}

.aqi-title {
    color: #fff;
    margin: 0;
    /* text-align: center; */
    /* font-size: 1.2rem; */
}

.button-container {
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.drawer-button, .csv-button {
    flex: 1;
    margin: 0 5px;
    font-size: 0.9rem;
}

.filter-tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    gap: 8px;
}

@media (max-width: 600px) {
    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .drawer-button, .csv-button {
        width: 80%;
        margin: 5px 0;
    }

    .filter-tags {
        flex-direction: column;
        align-items: center;
    }
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above all other content */
}