@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 0 20px rgba(255, 0, 0, 0.7);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
    }
}

/* .hazardous-animation {
    animation: pulse 1.5s infinite;
} */
